import React, { FC, useCallback, useMemo, useState } from 'react';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Flex, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { chineseNumeralFormatter, dollarLabelFormatter } from '@/src/libs/number-helper';
import AddFavoriteButton from '@/src/components/UI/AddFavoriteButton';
import HouseImageSlide from '@/src/components/UI/HouseImageSlide';
import Image from 'next/image';
import { listingImageSorter } from '@/utils/listingImageSorter';
import serializer from '@/src/libs/serializer';
import { ListingListEntity } from '@/swagger';
import { totalSizeFormatter } from '@/utils/dataConvertor';
import SwiperCore from 'swiper';
import ConditionalFragment from '@/components/common/ConditionalFragment';
import { isNotEmptyArray, isSet } from '@/utils/formatChecker';
import classNames from 'classnames';
// @ts-ignore
import Badge from '@/src/components/UI/Badge';
import { ShareSocial } from '@/components/feature/ShareSocial';
import { BlackPlaceholderImage } from '@/public/assets/images';

type Props = {
  listing: ListingListEntity;
  sizes: string | undefined;
  onFavButtonPressCallBack?: (sid: string, isFav: boolean) => void;
  className?: string;
};

export const ListingSkeleton: FC = () => {
  return (
    <Flex direction={'column'} w={'100%'}>
      <SkeletonCircle size='12' />
      <SkeletonText mt='4' noOfLines={4} spacing='4' />
      <SkeletonCircle mt='4' size='8' />
      <SkeletonText mt='4' noOfLines={6} spacing='4' />
    </Flex>
  );
};

const Listing: FC<Props> = ({ listing, sizes, onFavButtonPressCallBack, className }) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  const onSlideChange = useCallback((item) => {
    setActiveIndex(item.activeIndex);
  }, []);
  const [activeIndex, setActiveIndex] = useState(0);

  const hasDealPrice = useMemo(() => isSet(listing.dealPrice) && listing.dealPrice > 0, [listing.dealPrice]);

  const images = useMemo(() => {
    return Array.isArray(listing.listing_image) && isNotEmptyArray(listing.listing_image)
      ? listingImageSorter(listing.listing_image)
      : null;
  }, [listing.listing_image]);

  return (
    <Flex
      direction={'column'}
      w={'100%'}
      _hover={{
        boxShadow: { md: '-2px -2px 8px rgba(0, 0, 0, 0.12), 2px 2px 8px rgba(0, 0, 0, 0.12)' },
      }}
      boxShadow={'2px 2px 4px 0 #0000000a, -2px -2px 4px 0 #0000000a'}
      className={classNames('rounded overflow-hidden', className)}
    >
      <div className={'flex relative'}>
        <Badge state={serializer(listing.listing_state)} status={listing.status!} sold_at={listing.sold_at} />
        {images ? (
          <Swiper
            onInit={(swiper) => {
              swiper?.navigation?.init();
              swiper?.navigation?.update();
            }}
            className={'w-full flex flex-row'}
            slidesPerView={1}
            onSlideChange={onSlideChange}
            onSwiper={setSwiper}
          >
            {images.map((item) => (
              <SwiperSlide
                key={item.id}
                id={`listing-id:${listing?.sid}-id:${item.id}-order:${item.order}-tag:${item.tag}`}
              >
                <Link href={`/buy/${listing?.sid}`} passHref>
                  <a target={'_blank'} rel={'noreferrer noopener'}>
                    <HouseImageSlide sizes={sizes} url={item.watermark_image || ''} alt={`${listing?.title} slide`} />
                  </a>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className={'relative w-full'}>
            <Link href={`/buy/${listing?.sid}`} passHref>
              <a target={'_blank'} rel={'noreferrer noopener'}>
                <Image
                  className='bg-black'
                  src={BlackPlaceholderImage}
                  alt={'funwoo'}
                  width={3}
                  height={2}
                  objectFit={'contain'}
                  layout={'responsive'}
                />
              </a>
            </Link>
          </div>
        )}
        <button
          type={'button'}
          className={`absolute top-0 left-0 w-12 h-full ${activeIndex !== 0 ? 'visible' : 'invisible'}`}
          onClick={(e) => {
            e.stopPropagation();
            swiper?.slidePrev();
          }}
        >
          <Image
            height={24}
            width={24}
            layout={'fixed'}
            src={'/arrow_back_ios_white.png'}
            alt='image swiper arrow left'
          />
        </button>
        <button
          type={'button'}
          className={`absolute top-0 right-0  w-12 h-full  ${
            swiper?.allowSlideNext && !swiper.isEnd ? 'visible' : 'invisible'
          }`}
          onClick={(e) => {
            e.stopPropagation();
            swiper?.slideNext();
          }}
        >
          <Image
            height={24}
            width={24}
            layout={'fixed'}
            src={'/arrow_forward_ios_white.png'}
            alt='image swiper arrow right'
          />
        </button>
      </div>
      <Link href={`/buy/${listing?.sid}`} passHref>
        <a target={'_blank'} rel={'noreferrer noopener'} className={'px-3 py-2'} id={listing.sid}>
          <div className={'flex items-center mb-2 tracking-wide text-dark-grey uppercase'}>
            <ConditionalFragment condition={hasDealPrice}>
              <p className={'mr-4 align-text-bottom'}>
                {listing.dealPrice
                  ? `${chineseNumeralFormatter(listing.dealPrice)}${dollarLabelFormatter(listing.country ?? 'TW')}`
                  : ''}
                <span
                  className={classNames(
                    'ml-2',
                    'text-sm leading-4 text-gray500',
                    'md:hidden md:invisible md2:inline-block md2:visible'
                  )}
                >
                  成交價
                </span>
              </p>
            </ConditionalFragment>
            <p className={classNames('mr-auto', { 'line-through': hasDealPrice })}>
              {listing.price
                ? `${chineseNumeralFormatter(listing.price)}${dollarLabelFormatter(listing.country ?? 'TW')}`
                : ''}
            </p>
            <div className={'mr-4'}>
              <ShareSocial
                title={listing.title}
                url={`https://funwoo.com.tw/buy/${listing.sid}`}
                text={listing.feature_content ?? ''}
              />
            </div>
            {listing && (
              <AddFavoriteButton onFavButtonPressCallBack={onFavButtonPressCallBack} inCard sid={listing.sid} />
            )}
          </div>
          <div className={'flex items-center mb-2'}>
            <p className=' leading-normal text-center tracking-wide text-dark-grey uppercase'>
              {listing.room || listing.common_space || listing.bath
                ? `${listing.room ?? 0} 房 ${listing.common_space ?? 0} 廳 ${listing.bath ?? 0} 衛`
                : '-'}
            </p>
            <div className={'w-px bg-gray500 mx-3'} style={{ height: '1rem' }} />
            <p className=' leading-normal text-right tracking-wide text-dark-grey uppercase'>
              {totalSizeFormatter(listing.detail_total_area_size, listing.country, true)}
            </p>
          </div>
          <div className={'flex flex-row justify-start mb-2 items-center'}>
            <p className=' leading-normal tracking-wide uppercase text-gray700'>{listing.title}</p>
            {listing.display_building_project && listing.building_project !== '' ? (
              <div className={'w-px bg-gray500 mx-3'} style={{ height: '1rem' }} />
            ) : null}
            {listing.display_building_project && (
              <p className=' leading-normal tracking-wide uppercase text-gray700'>{listing.building_project}</p>
            )}
          </div>
          <p className=' leading-normal tracking-wide uppercase text-gray700'>{listing.display_address}</p>
        </a>
      </Link>
    </Flex>
  );
};
export default Listing;
